import React from 'react'
import './TextWithButtons.scss'
import Title from '../Title/Title'
import Button from '../Button/Button'
import PagePadding from '../PagePadding/PagePadding'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

export default ({ localProps }) => {
	return localProps[0] ? (
		<PagePadding>
			<article className={'text-and-buttons'}>
				{localProps[0].title ? (
					<Title
						heading={3}
						text={localProps[0].title}
						wave={false}
					/>
				) : null}
				<div className={'text-and-buttons__content'}>
					<p className={'text-and-buttons__paragraph'}>
						{localProps[0].text}
					</p>
					{localProps[0].buttonPrimary ||
					localProps[0].buttonSecondary ? (
						<div className={'text-and-buttons__button-holder'}>
							{localProps[0].buttonPrimary ? (
								<a
									href={localProps[0].buttonPrimary.to}
									target={
										localProps[0].buttonPrimary.to.includes(
											'mailto',
										)
											? '_blank'
											: undefined
									}
									rel={
										localProps[0].buttonPrimary.to.includes(
											'mailto',
										)
											? 'noopener noreferrer'
											: undefined
									}
									className={
										'text-and-buttons__button button secondary blue'
									}
								>
									{localProps[0].buttonPrimary.text}
								</a>
							) : null}
							{localProps[0].buttonSecondary ? (
								typeof localProps[0].buttonSecondary
									.download !== 'undefined' ? (
									<a
										href={
											localProps[0].buttonSecondary
												.download
										}
										onClick={() => {
											trackCustomEvent({
												// string - required - The object that was interacted with (e.g.video)
												category: 'event',
												// string - required - Type of interaction (e.g. 'play')
												action: 'download',
												// string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
												label: localProps[0].title,
											})
										}}
										target={'_blank'}
										rel="noopener noreferrer"
										className={
											'text-and-buttons__button button primary'
										}
									>
										{'Lees meer'}
									</a>
								) : (
									<Button
										text={
											localProps[0].buttonSecondary.text
										}
										action={
											localProps[0].buttonSecondary.action
										}
										onClick={() => {
											trackCustomEvent({
												// string - required - The object that was interacted with (e.g.video)
												category: 'event',
												// string - required - Type of interaction (e.g. 'play')
												action: 'mail-us',
												// string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
												label: localProps[0].title,
											})
										}}
										className={
											'text-and-buttons__button primary'
										}
									/>
								)
							) : null}
							{localProps[0].buttonTertiary ? (
								typeof localProps[0].buttonTertiary.download !==
								'undefined' ? (
									<a
										href={
											localProps[0].buttonTertiary
												.download
										}
										onClick={() => {
											trackCustomEvent({
												// string - required - The object that was interacted with (e.g.video)
												category: 'event',
												// string - required - Type of interaction (e.g. 'play')
												action: 'download',
												// string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
												label: localProps[0].title,
											})
										}}
										target={'_blank'}
										rel="noopener noreferrer"
										className={
											'text-and-buttons__button button primary'
										}
									>
										{'Lees meer'}
									</a>
								) : (
									<>
										<a
											href={
												localProps[0].buttonTertiary.to
											}
											target={'_blank'}
											rel="noopener noreferrer"
											className={
												'text-and-buttons__button button primary'
											}
										>
											{localProps[0].buttonTertiary.text}
										</a>
									</>
								)
							) : null}
						</div>
					) : null}
				</div>
			</article>
		</PagePadding>
	) : null
}
