import React from 'react'
import PrimaryLayout from '../layouts/PrimaryLayout'
import CoursePreview from '../components/CoursePreview/CoursePreview'

import config from 'react-reveal/globals'
import '../styles/normalize.scss'
import '../styles/variables.scss'
import '../styles/fonts.scss'
import '../styles/global.scss'
import '../styles/link.scss'
import TextWithButtons from '../components/TextWithButtons/TextWithButtons'
import { useStaticQuery, graphql } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'
import { encodeToReadableUrl } from '../utils/encodeToReadableUrl'
config({ ssrFadeout: true })

export default function Impression() {
	const { allWordpressWpReis } = useStaticQuery(
		graphql`
			query {
				allWordpressWpReis(limit: 1) {
					nodes {
						acf {
							reis_id
							reis_promotie {
								punt_1
								punt_2
								punt_3
								punt_4
								tonen
							}
							intro_text
							reis_blok {
								reis_beschrijving
								reis_title
							}
							sfeer_fotos {
								alt_text
								source_url
								localFile {
									url
									childImageSharp {
										# Try editing the "maxWidth" value to generate resized images.
										fluid(maxWidth: 600, maxHeight: 600) {
											...GatsbyImageSharpFluid_withWebp
										}
									}
								}
							}
						}
						wordpress_id
						link
					}
				}
			}
		`,
	)

	const showVacationPromo =
		allWordpressWpReis.nodes.length &&
		allWordpressWpReis.nodes[0].acf.reis_promotie.tonen

	// const images = [
	// 	{
	// 		src:
	// 			allWordpressWpMedia.nodes.length &&
	// 			allWordpressWpMedia.nodes[0].source_url,
	// 		alt:
	// 			allWordpressWpMedia.nodes.length &&
	// 			allWordpressWpMedia.nodes[0].alt_text,
	// 	},
	// 	{
	// 		src: '../../images/inbalans-image-4.jpg',
	// 		alt: 'Beertjes2',
	// 	},
	// 	{
	// 		src: '../../images/inbalans-image-5.jpg',
	// 		alt: 'Beertjes3',
	// 	},
	// 	{
	// 		src: '../../images/inbalans-image-6.jpg',
	// 		alt: 'Beertjes4',
	// 	},
	// 	{
	// 		src: '../../images/inbalans-image-7.jpg',
	// 		alt: 'Beertjes4',
	// 	},
	// ]

	const coursePreviewData = {
		image1: {
			src:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[0],
			alt:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[0].alt_text,
		},
		image2: {
			src:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[1],
			alt:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[1].alt_text,
		},
		image3: {
			src:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[2],
			alt:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[2].alt_text,
		},
		image4: {
			src:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[3],
			alt:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[3].alt_text,
		},
		incentive: {
			text:
				allWordpressWpReis.nodes.length &&
				ReactHtmlParser(
					allWordpressWpReis.nodes[0].acf.reis_blok.reis_beschrijving,
				),
		},
		article: {
			title: 'Wat je kunt verwachten',
			excerpt:
				allWordpressWpReis.nodes.length &&
				ReactHtmlParser(allWordpressWpReis.nodes[0].acf.intro_text),
			list: allWordpressWpReis.nodes.length && [
				allWordpressWpReis.nodes[0].acf.reis_promotie.punt_1,
				allWordpressWpReis.nodes[0].acf.reis_promotie.punt_2,
				allWordpressWpReis.nodes[0].acf.reis_promotie.punt_3,
				allWordpressWpReis.nodes[0].acf.reis_promotie.punt_4,
			],
			button: {
				text: 'BELEEF DE REIS',
				to: `/vakantie/${encodeToReadableUrl(
					allWordpressWpReis.nodes[0].link,
				)}/${
					allWordpressWpReis.nodes.length &&
					allWordpressWpReis.wordpress_id
				}`,
			},
		},
	}

	const coursePreviewData2 = {
		image1: {
			src:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[0],
			alt:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[0].alt_text,
		},
		image2: {
			src:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[1],
			alt:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[1].alt_text,
		},
		image3: {
			src:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[2],
			alt:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[2].alt_text,
		},
		image4: {
			src:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[3],
			alt:
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[3] &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[3].alt_text,
		},
		image5: {
			src:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[4]
					? allWordpressWpReis.nodes[0].acf.sfeer_fotos[4]
					: null,
			alt:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[4] &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[4].alt_text,
		},
	}

	return (
		<PrimaryLayout>
			{/* <InfoBlock /> */}

			<CoursePreview data={coursePreviewData2} />

			<TextWithButtons
				localProps={[
					{
						title: 'Schaatsreis Zweden',
						text: `Wij organiseren iedere winter (eind januari) een fantastische reis naar het Zweedse natuurijs, de Zweedse InBalans Natuurijs Tiendaagse.`,
						buttonPrimary: {
							text: 'Beleef de reis',
							to: `/vakantie/${encodeToReadableUrl(
								allWordpressWpReis.nodes[0].link,
							)}/${
								allWordpressWpReis.nodes.length &&
								allWordpressWpReis.wordpress_id
							}`,
						},
					},
				]}
			/>

			{/*<Hero />*/}
			{showVacationPromo && <CoursePreview data={coursePreviewData} />}
		</PrimaryLayout>
	)
}
