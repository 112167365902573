import React, { useState, useEffect } from 'react'

const ModalManager = ({ toggle, content, showByDefault }) => {
	const [isShown, setIsShown] = useState(false)

	useEffect(() => {
		setIsShown(showByDefault)
	}, [showByDefault])

	if (!showByDefault) {
		return null
	}

	const hide = () => setIsShown(false)
	const show = () => setIsShown(true)

	return (
		<>
			{toggle && toggle(show)}
			{isShown && content(hide)}
		</>
	)
}

export default ModalManager
