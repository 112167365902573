import React, { useContext } from 'react'
import './CoursePreview.scss'
import Title from '../Title/Title'
import Button from '../Button/Button'
import Wave from '../../images/icons/Wave'
import Img from 'gatsby-image'
import { HeaderContext } from '../Header/Header'

export default ({ data }) => {
	const { image1, image2, image3, image4, image5, incentive, article } = data
	const HeaderCTX = useContext(HeaderContext)

	return (
		<section className={'course-preview'}>
			{image1 && (
				<figure className={'course-preview__row--image'}>
					<Img
						fluid={
							image1.src.localFile
								? image1.src.localFile.childImageSharp.fluid
								: image1.src.source_url
						}
						alt={image1.alt}
						style={{ zIndex: '-1' }}
					/>
				</figure>
			)}
			<figure className={'course-preview__row--image'}>
				<Img
					fluid={
						image2.src.localFile
							? image2.src.localFile.childImageSharp.fluid
							: image2.src.source_url
					}
					alt={image2.alt}
					style={{ zIndex: '-1' }}
				/>
			</figure>
			<figure className={'course-preview__row--image'}>
				<Img
					fluid={
						image3.src.localFile
							? image3.src.localFile.childImageSharp.fluid
							: image3.src.source_url
					}
					alt={image3.alt}
					style={{ zIndex: '-1' }}
				/>
			</figure>
			{incentive ? (
				<article className={'course-preview__incentive'}>
					{incentive.text}
					<Wave
						winter={HeaderCTX.winterTime}
						color={
							HeaderCTX.winterTime
								? 'rgba(234, 134, 45, 1)'
								: '#005394'
						}
					/>
				</article>
			) : (
				''
			)}
			{image5 ? (
				<figure className={'course-preview__row--image'}>
					<Img
						fluid={
							image5 && image5.src && image5.src.localFile
								? image5.src.localFile.childImageSharp.fluid
								: image4.src.localFile.childImageSharp.fluid
						}
						alt={image5 && image5.alt ? image5.alt : image4.alt}
						style={{ zIndex: '-1' }}
					/>
				</figure>
			) : (
				<article className={'course-preview__article'}>
					<div className="course-preview__article--container">
						<Title
							heading={3}
							className={'course-preview__title white'}
							wave={true}
							text={article.title}
						/>
						<div className={'course-preview__excerpt'}>
							{article.excerpt}
						</div>
						<ul className={'course-preview__list'}>
							{article.list.length
								? article.list.map((item) => {
										return (
											<li
												key={item}
												className={
													'course-preview__list--item'
												}
											>
												{item}
											</li>
										)
								  })
								: null}
						</ul>
						<div className="button-container">
							<Button
								text={article.button.text}
								to={article.button.to}
								className={'secondary'}
							/>
						</div>
					</div>
				</article>
			)}
			<figure className={'course-preview__row--image'}>
				<Img
					fluid={image4.src.localFile.childImageSharp.fluid}
					alt={image4.alt}
					style={{ zIndex: '-1' }}
				/>
			</figure>
		</section>
	)
}
