import React from 'react'
import './ModalBox.scss'
import HamburgerMenu from '../../images/icons/HamburgerMenu/HamburgerMenu'

const ModalBox = (props) => {
	const { children, close } = props
	return (
		<div className={'ModalBox'}>
			<button className={'ModalBox__close'} onClick={close}>
				<HamburgerMenu addedClass={' open white'} />
			</button>
			{children}
		</div>
	)
}

export default ModalBox
