import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer/Footer'
import { Header, HeaderContextProvider } from '../components/Header/Header'
import { useStaticQuery, graphql } from 'gatsby'
import PagePadding from '../components/PagePadding/PagePadding'
import { isWinter } from '../utils/isWinter'
import SocialComponent from '../components/SocialComponents/SocialComponent'

import Modal from '../components/Modal/Modal'
import ModalManager from '../components/Modal/ModalManager'
import ModalBox from '../components/Modal/ModalBox'

const PrimaryLayout = ({ children }) => {
	const [showCookie, setCookie] = useState(false)

	useEffect(() => {
		setCookie(localStorage.getItem('pandemic-measures'))
	}, [showCookie])

	const localMeta = {
		home: {
			title: 'InBalans | Schaats- en skeelerschool Alkmaar',
			description:
				'Schaats- en skeelerschool gevestigd in Alkmaar, Noord-Holland. InBalans verzorgt toegankelijke lessen en sportreizen voor jong en oud.',
			keywords:
				'schaatsen, schaatsreizen, sportreizen, schaatsreizen, skeeler, schaatsschool, skeelerschool, jeugd, oud, skeeleren, mountainbiken, alkmaar, sport, sporten, sportschool, leren schaatsen, BSO, buitenschoolseopvang, kinderen, uitjes, bedrijfsuitjes, heerhugowaard, heiloo, sportieve reizen',
		},
		schaatsreis: {
			title: 'InBalans | Sportieve reizen',
			description:
				'Van beginner tot expert, iedereen kan mee op onze sportieve all-in schaats reis naar Zweden.',
			keywords:
				'schaatsen, schaatsreizen, sportreizen, schaatsreizen, schaatsschool, jeugd, oud, alkmaar, sport, sporten, sportschool, leren schaatsen, kinderen, uitjes, bedrijfsuitjes, sportieve reizen',
		},
		impression: {
			title: 'InBalans | Sportieve reizen', // CHANGES PER CURRENT PAGE
			description:
				'Van beginner tot expert, iedereen kan mee op onze sportieve all-in schaats reis naar Zweden.', // CHANGES PER CURRENT PAGE
			keywords:
				'schaatsen, schaatsreizen, sportreizen, schaatsreizen, schaatsschool, jeugd, oud, alkmaar, sport, sporten, sportschool, leren schaatsen, kinderen, uitjes, bedrijfsuitjes, sportieve reizen', // CHANGES PER CURRENT PAGE
		},
	}
	const links = [
		{
			label: 'Nieuws',
			to: '/nieuws',
		},
		{
			label: 'Kalender',
			to: '/kalender',
		},
		{
			label: 'Materiaal & Onderhoud',
			to: '/materiaal-en-onderhoud/',
		},
		{
			label: 'Vacatures',
			to: '/vacatures/',
		},
		{
			label: 'Veelgestelde vragen',
			to: '/faq',
		},
		{
			label: 'Contact',
			to: '#contact',
		},
	]

	const {
		site,
		winterDate,
		zomerDate,
		allWordpressAcfCovidPopup,
	} = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
					}
				}
				winterDate: wordpressPage(title: { eq: "Winter" }) {
					acf {
						start_datum
					}
				}
				zomerDate: wordpressPage(title: { eq: "Zomer" }) {
					acf {
						start_datum
					}
				}
				allWordpressAcfCovidPopup {
					edges {
						node {
							acf {
								tekst
							}
						}
					}
				}
			}
		`,
	)

	return (
		<>
			<Helmet>
				<html
					lang="nl"
					className={
						isWinter(
							winterDate.acf.start_datum,
							zomerDate.acf.start_datum,
						)
							? 'winter'
							: 'summer'
					}
				/>
				<meta charset="UTF-8" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0"
				/>
				{/*DYNAMIC FROM WORDPRESS*/}
				<title>{site.siteMetadata.title}</title>
				<meta name="subject" content={site.siteMetadata.title} />
				<meta name="topic" content={site.siteMetadata.title} />
				<meta name="og:title" content={site.siteMetadata.title} />
				<meta name="description" content={localMeta.home.description} />
				<meta
					name="og:description"
					content={localMeta.home.description}
				/>
				<meta name="keywords" content={localMeta.home.keywords} />

				{typeof window !== 'undefined' &&
				(window.location.href ===
					'https://www.inbalansalkmaar.nl/winter' ||
					window.location.href ===
						'https://www.inbalansalkmaar.nl/winter/') &&
				isWinter(
					winterDate.acf.start_datum,
					zomerDate.acf.start_datum,
				) ? (
					<link
						rel="canonical"
						href="https://www.inbalansalkmaar.nl/"
					/>
				) : typeof window !== 'undefined' &&
				  (window.location.href ===
						'https://www.inbalansalkmaar.nl/zomer' ||
						window.location.href ===
							'https://www.inbalansalkmaar.nl/zomer/') &&
				  !isWinter(
						winterDate.acf.start_datum,
						zomerDate.acf.start_datum,
				  ) ? (
					<link
						rel="canonical"
						href="https://www.inbalansalkmaar.nl/"
					/>
				) : (
					<link
						rel="canonical"
						href={`https://www.inbalansalkmaar.nl${
							typeof window !== 'undefined'
								? window.location.pathname
								: '/'
						}
						`}
					/>
				)}
				<meta http-equiv="X-UA-Compatible" content="ie=edge" />
				<meta name="HandheldFriendly" content="True" />
				<meta name="copyright" content="In Balans Alkmaar" />
				<meta name="url" content="https://www.inbalansalkmaar.nl" />
				{/* Maybe get the extension of the URL? */}
				<meta itemProp="name" content="In Balans Alkmaar" />
				<meta itemProp="description" content="description" />
				<meta
					itemProp="image"
					content="/images/inbalans-nieuw-winter.jpg"
				/>
				<meta
					name="image"
					content="/images/inbalans-nieuw-winter.jpg"
				/>
				{/*// <!-- Open Graph general (Facebook, Pinterest & Google+) -->*/}
				<meta name="twitter:card" content="summary" />
				<meta
					name="og:image"
					content="/images/inbalans-nieuw-winter.jpg"
				/>
				<meta name="og:url" content="CURRENT" />
				<meta name="og:site_name" content="In Balans Alkmaar" />
				<meta name="og:locale" content="nl_nl" />
				<meta name="fb:admins" content="384740665206638" />
				<meta name="og:type" content="website" />
				<meta
					name="google-site-verification"
					content="6V4Sf-B_t3Dqp-ehoM8uV3oQyMzHxzfrKYDioRPBSuc"
				/>
				<script type="application/ld+json">
					{`{
						"@context": "https://schema.org",
						"@type": "Organization",
						"name": "In Balans Alkmaar",
						"url": "https://www.inbalansalkmaar.nl/",
						"logo": "https://www.inbalansalkmaar.nl/images/logo.png",
						"sameAs": [
							"https://www.facebook.com/inbalans.alkmaar/",
							"https://twitter.com/inbalansalkmaar",
							"https://www.instagram.com/inbalansalkmaar/",
							"https://www.youtube.com/user/inbalansalkmaar01"
						]
					}`}
				</script>
				<script type="application/ld+json">
					{`{
					"@context": "https://schema.org/",
					"@type": "SportsClub",
					"description":"Schaats- en skeelerschool gevestigd in Alkmaar, Noord-Holland. InBalans verzorgt toegankelijke lessen en sportreizen voor jong en oud.",
					"image":"https://www.inbalansalkmaar.nl/images/inbalans-nieuw-winter.jpg",
					"name":"In Balans Alkmaar",
					"url":"https://www.inbalansalkmaar.nl/"
				}`}
				</script>
			</Helmet>
			<HeaderContextProvider>
				<Header links={links} />
				<main>{children}</main>
				<ModalManager
					showByDefault={!showCookie}
					content={(hide) => (
						<Modal>
							<ModalBox close={() => onModalClose(hide)}>
								<div
									dangerouslySetInnerHTML={{
										__html:
											allWordpressAcfCovidPopup.edges[0]
												.node.acf.tekst,
									}}
								/>
							</ModalBox>
						</Modal>
					)}
				/>
				<PagePadding>
					<SocialComponent />
				</PagePadding>
				<Footer />
			</HeaderContextProvider>
		</>
	)
}

function onModalClose(hide) {
	localStorage.setItem('pandemic-measures', 'seen')
	hide()
}

export default PrimaryLayout
