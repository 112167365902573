import React from 'react'
import { createPortal } from 'react-dom'

const root =
	typeof document !== `undefined` ? document.getElementById('portal') : null

const Modal = ({ children }) => {
	if (!root) {
		return null
	}
	return createPortal(
		<div className={'modal-background'}>
			<div className="modal">{children}</div>
		</div>,
		root,
	)
}

export default Modal
